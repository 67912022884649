import React, { useState } from 'react';
import './startpage.css';
import { register, login, resetPassword } from './AuthService.js';
import { useNavigate } from 'react-router-dom';
import logo from './logo.png'; // Import the logo

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isRegister, setIsRegister] = useState(false);
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleForgotPassword = async () => {
    try {
      await resetPassword(email);
      setMessage('Password reset email sent');
    } catch (error) {
      setMessage('Error: ' + error.message);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (isRegister) {
        await register(email, password);
        setMessage('Registered successfully');
      } else {
        await login(email, password);
        setMessage('Logged in successfully');
      }
      // Redirect to the App page
      navigate('/app'); // Changed from navigate('/') to navigate('/app')
    } catch (error) {
      setMessage('Error: ' + error.message);
    }
  };

  const toggleRegister = () => {
    setIsRegister(!isRegister);
  };

  return (
    <div className="container">
      <img src={logo} alt="Logo" className="logo" /> {/* Add the logo */}
      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <label>
            Email:
            <input
              type="email"
              value={email}
              onChange={handleEmailChange}
              required
            />
          </label>
          <label>
            Password:
            <input
              type="password"
              value={password}
              onChange={handlePasswordChange}
              required
            />
          </label>
          <input type="submit" value={isRegister ? 'Register' : 'Log In'} className={isRegister ? 'button-register' : 'button-login'} />
        </form>
        <div className="button-container">
          <button className="toggle-button" onClick={toggleRegister}>
            {isRegister
              ? 'Already have an account? Log In'
              : "Don't have an account? Register"}
          </button>
          <button className="forgot-password" onClick={handleForgotPassword}>
            Forgot Password?
          </button>
        </div>
      </div>
      {message && <div className="message">{message}</div>}
    </div>
  );
};

export default LoginPage;