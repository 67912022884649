import { initializeApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { setDoc, doc } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBCfV0RrszfJFcKAFiazftANnLsYyrjEO4",
  authDomain: "tails-ai-35359.firebaseapp.com",
  databaseURL: "https://tails-ai-35359-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "tails-ai-35359",
  storageBucket: "tails-ai-35359.appspot.com",
  messagingSenderId: "606703961044",
  appId: "1:606703961044:web:8c0b6583b1c4e90cac4c41",
  measurementId: "G-W36EBQMZCH  "
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get the Firebase auth instance
const auth = getAuth(app);

// Get the Firestore database instance
const db = getFirestore(app);

const register = async (email, password, displayName) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    await setDoc(doc(db, "users", user.uid), {
      displayName: displayName,
      email: email
    });
    return user;
  } catch (error) {
    console.error("Error registering user", error);
    throw error;
  }
};

const login = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    return user;
  } catch (error) {
    console.error("Error logging in user", error);
    throw error;
  }
};

export { register, login, auth };
